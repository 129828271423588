<template>
  <div>
    <NForm
      ref="searchForm"
      inline
      label-placement="left"
    >
      <NFormItem :label="searchTypeReqData.input.label">
        <NInput
          style="width: 300px;"
          maxlength="50"
          show-count
          clearable
          v-model:value="formValue.input"
        />
      </NFormItem>
      <NFormItem :label="searchTypeReqData.select.label">
        <NSelect
          style="width: 300px;"
          :multiple="searchTypeReqData.select.multiple"
          :options="selectList"
          clearable
          v-model:value="formValue.select"
        />
      </NFormItem>
      <div>
        <NButton
          type="primary"
          style="margin-right: 10px;"
          @click="handleSearch"
        >搜索</NButton>
        <NButton @click="handleClear">清除</NButton>
      </div>
    </NForm>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import {
    NForm,
    NFormItem,
    NInput,
    NSelect,
    NButton
  } from 'naive-ui';

  const emit = defineEmits(['search-click']);
  const props = defineProps({
    searchTypeReqData: {
      type: Object,
      default: () => ({
        input: {
          label: '输入：',
          reqKey: 'input'
        },
        select: {
          label: '选择：',
          reqKey: 'select',
          multiple: false
        }
      })
    },
    selectList: {
      type: Array,
      default: () => []
    }
  });

  const searchForm = ref(null);

  function getDefaultFormValue() {
    return {
      input: '',
      select: null
    }
  }
  const formValue = reactive(getDefaultFormValue());

  function handleSearch() {
    emit('search-click');
  }
  function handleClear() {
    Object.assign(formValue, getDefaultFormValue());
    handleSearch();
  }

  defineExpose({
    getSearchParams() {
      const searchTypeReqData = props.searchTypeReqData;
      const obj = {};
      ['input', 'select'].forEach(item => {
        const key = searchTypeReqData[item].reqKey;
        let value = formValue[item];
        typeof value === 'string' && (value = value.trim());
        if (Array.isArray(value) && value.length > 0) {
          value = value.sort((a, b) => a - b).join(',');
        }
        value && (obj[key] = value);
      });
      return obj;
    }
  });
</script>