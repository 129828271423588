<template>
  <div class="study-situation">
    <NTabs
      type="card"
      class="ss-tabs"
      v-model:value="currentTab"
      @update:value="handleCurrentTabChange"
    >
      <NTabPane
        v-for="(title, type) in listTypeValTitle"
        :key="type"
        :name="type"
        :tab="title"
      ></NTabPane>
    </NTabs>
    <div class="ss-list-box">
      <JobList
        v-show="Number(currentTab) === listTypeTagVal.JOB"
        ref="jobListRef"
        @list-search-update="handleJobItemChange"
      />
      <SkillList
        v-show="Number(currentTab) === listTypeTagVal.SKILL"
        ref="skillListRef"
        @list-search-update="handleSkillItemChange"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NTabs, NTabPane } from 'naive-ui';

  import JobList from './components/JobList.vue';
  import SkillList from './components/SkillList.vue';

  import { listTypeValTitle, listTypeTagVal } from '@/enumerators/study-situation.js'
  
  const currentTab = ref('');
  const jobListRef = ref(null);
  const skillListRef = ref(null);

  function handleCurrentTabChange(tabName) {
    switch (Number(tabName)) {
      case listTypeTagVal.JOB:
        jobListRef.value.initReqs();
        break;
      case listTypeTagVal.SKILL:
        skillListRef.value.initReqs();
        break;
    }
  }

  function handleJobItemChange() {
    skillListRef.value.falseInited();
  }
  function handleSkillItemChange() {
    jobListRef.value.falseInited();
  }

  function initVars() {
    const listTypeValList = Object.keys(listTypeValTitle);
    listTypeValList.length > 0 && (currentTab.value = listTypeValList[0]);
  }

  initVars();
</script>

<style lang="less" scoped>
  .study-situation {
    background-color: #fff;
  }

  .ss-tabs {
    :deep(.n-tabs-tab) {
      width: 75px;
      margin-right: -1px;
      justify-content: center !important;
      border-radius: 0;
    }
    :deep(.n-tabs-tab-pad) {
      width: 0;
    }
    :deep(.n-tabs-pad) {
      border: 1px solid #efeff5;
      background-color: #f7f7fa;
    }
  }

  .ss-list-box {
    padding: 8px 20px 20px;
  }
</style>