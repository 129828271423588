<template>
  <div>
    <SearchBar
      ref="searchBarRef"
      @search-click="handleSearch"
      :searchTypeReqData="{
        input: {
          label: '搜索关键词：',
          reqKey: 'title'
        },
        select: {
          label: '需要能力：',
          reqKey: 'search',
          multiple: true
        }
      }"
      :selectList="skillList"
    />
    <div class="add-bar">
      <NButton type="primary" @click="handleClickCreate">新增技能</NButton>
    </div>
    <div>
      <NDataTable
        :single-line="false"
        remote
        :row-key="row => row.id"
        :columns="jobListColumns"
        :data="jobList"
        :pagination="jobListPagination"
      />
    </div>
    
    <EditJobModal
      ref="editJobModalRef"
      :skillList="skillList"
      @item-change="handleItemChange"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, h } from 'vue';
  import { NButton, NDataTable, NTime, useMessage, useDialog } from 'naive-ui';

  import SearchBar from './SearchBar.vue';
  import EditJobModal from './EditJobModal.vue';
  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { listTypeTagVal } from '@/enumerators/study-situation.js'

  import { reqList, delItem } from '@/api/planning.js';

  const message = useMessage();
  const dialog = useDialog();
  const { SUCCESS } = resStatusEnum;

  const emit = defineEmits(['list-search-update']);

  const searchBarRef = ref(null);
  const loading = ref(false);
  const jobListColumns = [
    { title: '技能名称', key: 'title' },
    { title: '需要能力', render: ({ data_list }) => {
      let needSkillStr = '';
      if (Array.isArray(data_list)) {
        needSkillStr = data_list.map(({ title }) => title).join('、');
      }
      return h('span', null, needSkillStr);
    } },
    { title: '创建人', key: 'admin_username' },
    { title: '创建时间', render: ({ create_time }) => h(NTime, { time: Number(create_time) * 1000 }) },
    { title: '操作', render: row => h('div', null, [
      h(NButton, { text: true, type: 'primary', style: 'margin-right: 10px;', onClick: () => { editJobModalRef.value.openEdit(row.id) } }, { default: () => '编辑' }),
      h(NButton, { text: true, type: 'error', onClick: () => { handleDelItem(row); } }, { default: () => '删除' })
    ]) }
  ];
  const jobListPagination = reactive({
    itemCount: 0,
    page: 1,
    pageSize: 10,
    showSizePicker: true,
    pageSizes: [10, 20, 30],
    onChange: (page) => {
      jobListPagination.page = page;
      reqJobList();
    },
    onUpdatePageSize: (pageSize) => {
      jobListPagination.pageSize = pageSize;
      jobListPagination.onChange(1);
    }
  });
  const jobList = ref([]);
  const skillList = ref([]);

  function reqJobList() {
    const searchParams = searchBarRef.value.getSearchParams();
    const reqData = {
      type: listTypeTagVal.JOB,
      ...searchParams,
      page: jobListPagination.page,
      page_size: jobListPagination.pageSize
    }
    loading.value = true;
    reqList(reqData).then(({ code, data }) => {
      const { total, list } = data;
      jobListPagination.itemCount = Number(total) || 0;
      if (code === SUCCESS && Array.isArray(list)) {
        jobList.value = list;
      }
    }).finally(() => {
      loading.value = false;
    });
  }
  function reqSkillList() {
    reqList({
      type: listTypeTagVal.SKILL,
      page: 1,
      page_size: 50000
    }).then(({ code, data }) => {
      const { list } = data;
      if (code === SUCCESS && Array.isArray(list)) {
        skillList.value = list.map(({ title, id, score }) => ({ label: title, value: id, score }));
      }
    });
  }

  function handleSearch() {
    jobListPagination.onChange(1);
  }

  function handleDelItem({ title, id }) {
    dialog.warning({
      title: '删除确认',
      content: `确定删除技能“${title}”？`,
      positiveText: '删除',
      negativeText: '取消',
      onPositiveClick: () => {
        loading.value = true;
        delItem({
          type: listTypeTagVal.JOB,
          'PlanningPosi[id]': id
        }).then(({ code }) => {
          if (code === SUCCESS) {
            message.success(`技能“${title}”已删除`);
            handleItemChange();
          }
        }).finally(() => {
          // loading.value = false;
        });
      },
      onNegativeClick: () => {}
    });
  }

  const editJobModalRef = ref(null);
  function handleClickCreate() {
    editJobModalRef.value.openCreate();
  }
  function handleItemChange() {
    reqJobList();
    emit('list-search-update');
  }

  let reqsInited = false;
  function initReqs() {
    if (!reqsInited) {
      reqsInited = true;
      reqJobList();
      reqSkillList();
    }
  }

  onMounted(() => {
    initReqs();
  });

  defineExpose({
    falseInited: () => {
      reqsInited = false;
    },
    initReqs
  });
</script>

<style lang="less" scoped>
  .add-bar {
    margin: -9px 0 15px;
    text-align: right;
  }
</style>