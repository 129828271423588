<template>
  <div>
    <NModal
      preset="dialog"
      :show-icon="false"
      :mask-closable="false"
      style="width: 450px;"
      :title="modalTitle"
      v-model:show="modalShow"
      positive-text="提交"
      negative-text="取消"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
    >
      <NForm
        ref="formRef"
        :rules="formRules"
        :model="formValue"
        style="padding: 20px 0 0;"
      >
        <NFormItem label="能力名称" path="name">
          <NInput
            clearable
            maxlength="50"
            show-count
            placeholder="请填写能力名称"
            v-model:value="formValue.name"
            @blur="handleNameBlur"
          />
        </NFormItem>
      </NForm>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, computed, reactive } from 'vue';
  import { NModal, NForm, NFormItem, NInput, NInputNumber, useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { listTypeTagVal } from '@/enumerators/study-situation.js'
  import { resStatusEnum } from '@/enumerators/http.js';

  import { createItem, editItem, reqItemDetail } from '@/api/planning.js';

  const message = useMessage();
  const { SUCCESS } = resStatusEnum;

  const emit = defineEmits(['item-change']);

  const loading = ref(false);
  const modalTitle = ref('新增能力');
  const modalShow = ref(false);
  const skillID = ref('');
  const isEdit = computed(() => skillID.value !== '');

  function handleSubmit() {
    formRef.value.validate(errors => {
      if (!errors) {
        let reqFn = null;
        let successMsg = '';
        if (isEdit.value) {
          successMsg = '编辑成功';
          reqFn = editItem;
        } else {
          successMsg = '新增成功';
          reqFn = createItem;
        }
        loading.value = true;
        reqFn(getReqData()).then(({ code }) => {
          if (code === SUCCESS) {
            message.success(successMsg);
            emit('item-change');
            closeModal();
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
    return false;
  }
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function closeModal() {
    resetFormValue();
    modalShow.value = false;
  }

  const formRef = ref(null);
  const formRules = {
    name: {
      required: true,
      message: '请填写能力名称',
      trigger: 'blur'
    }
  };
  function getDefaultFormValue() {
    return {
      name: ''
    }
  }
  const formValue = reactive(getDefaultFormValue());
  function handleNameBlur() {
    formValue.name = formValue.name.trim();
  }
  function setFormValue({ title }) {
    Object.assign(formValue, {
      name: title
    });
  }
  function getReqData() {
    const data = {
      type: listTypeTagVal.SKILL
    };
    if (isEdit.value) {
      data['PlanningSkill[id]'] = skillID.value;
    }
    data['PlanningSkill[title]'] = formValue.name;
    return data;
  }

  defineExpose({
    openCreate() {
      modalTitle.value = '新增能力';
      skillID.value = '';
      modalShow.value = true;
    },
    openEdit(id) {
      modalTitle.value = '编辑能力';
      skillID.value = id;
      loading.value = true;
      reqItemDetail({
        type: listTypeTagVal.SKILL,
        'PlanningSkill[id]': id
      }).then(({ code, data }) => {
        if (code === SUCCESS) {
          setFormValue(data);
          modalShow.value = true;
        }
      }).finally(() => {
        loading.value = false;
      });
    }
  });
</script>