<template>
  <div>
    <NModal
      preset="dialog"
      :show-icon="false"
      :mask-closable="false"
      style="width: 600px;"
      :title="modalTitle"
      v-model:show="modalShow"
      positive-text="提交"
      negative-text="取消"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
    >
      <NForm
        ref="formRef"
        :rules="formRules"
        :model="formValue"
        style="padding: 20px 0 0;"
      >
        <NFormItem label="技能名称" path="name">
          <NInput
            clearable
            maxlength="50"
            show-count
            placeholder="请填写技能名称"
            v-model:value="formValue.name"
            @blur="handleNameBlur"
          />
        </NFormItem>
        <NFormItem label="拥有能力" path="skills">
          <NSelect
            multiple
            clearable
            :options="skillList"
            placeholder="请选择能力并填写分数标准"
            v-model:value="formValue.skills"
            @update:value="handleSkillsChange"
          />
        </NFormItem>
        <NFormItem
          v-for="(item, index) in formValue.passLines"
          :key="item.skillID"
          label-placement="left"
          label-width="250"
          :rule="formRules.passLine"
          :path="`passLines[${index}].score`"
          class="skill-passling-item"
        >
          <template #label>
            <div class="skill-name" :title="item.skillName">{{ item.skillName }}</div>
          </template>
          <NInputNumber
            class="score-input"
            clearable
            :show-button="false"
            placeholder="请填写分数标准"
            v-model:value="item.score"
            @blur="handleScoreBlur(item)"
          >
            <template #suffix>%</template>
          </NInputNumber>
          <NButton
            size="tiny"
            type="error"
            circle
            class="del-skill-passline"
            @click="removeSkill(index)"
          >
            <template #icon><NIcon><MinusFilled /></NIcon></template>
          </NButton>
        </NFormItem>
      </NForm>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, computed, reactive } from 'vue';
  import { NModal, NForm, NFormItem, NInput, NSelect, NButton, NIcon, NInputNumber, useMessage } from 'naive-ui';
  import { MinusFilled } from '@vicons/material';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { listTypeTagVal } from '@/enumerators/study-situation.js'
  import { resStatusEnum } from '@/enumerators/http.js';

  import { createItem, editItem, reqItemDetail } from '@/api/planning.js';

  const message = useMessage();
  const { SUCCESS } = resStatusEnum;

  const emit = defineEmits(['item-change']);
  const props = defineProps({
    skillList: {
      type: Array,
      default: () => []
    }
  });

  const loading = ref(false);
  const modalTitle = ref('新增技能');
  const modalShow = ref(false);
  const jobID = ref('');
  const isEdit = computed(() => jobID.value !== '');
  const idSkillData = computed(() => {
    const obj = {};
    const list = props.skillList;
    if (Array.isArray(list) && list.length > 0) {
      list.forEach(item => {
        obj[item.value] = item;
      });
    }
    return obj;
  });

  function handleSubmit() {
    formRef.value.validate(errors => {
      if (!errors) {
        let reqFn = null;
        let successMsg = '';
        if (isEdit.value) {
          successMsg = '编辑成功';
          reqFn = editItem;
        } else {
          successMsg = '新增成功';
          reqFn = createItem;
        }
        loading.value = true;
        reqFn(getReqData()).then(({ code }) => {
          if (code === SUCCESS) {
            message.success(successMsg);
            emit('item-change');
            closeModal();
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
    return false;
  }
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function closeModal() {
    resetFormValue();
    modalShow.value = false;
  }

  const formRef = ref(null);
  const formRules = {
    name: {
      required: true,
      message: '请填写技能名称',
      trigger: 'blur'
    },
    skills: {
      type: 'array',
      required: true,
      message: '请选择能力并填写分数标准',
      trigger: ['blur', 'change']
    },
    passLine: {
      type: 'number',
      required: true,
      message: '请填写分数标准',
      trigger: 'blur'
    }
  };
  function getDefaultFormValue() {
    return {
      name: '',
      skills: null,
      passLines: []
    }
  }
  function getPassLineItem(skillID, skillName, score) {
    return {
      skillID,
      skillName,
      score
    }
  }
  const formValue = reactive(getDefaultFormValue());
  function handleNameBlur() {
    formValue.name = formValue.name.trim();
  }
  function handleSkillsChange(list) {
    const passLines = formValue.passLines;
    const newPassLines = [];
    const skillIDIndexInPassLines = {};
    const idSkillDataLink = idSkillData.value;
    passLines.forEach(({ skillID }, index) => {
      skillIDIndexInPassLines[skillID] = index;
    });
    list.forEach(skillID => {
      const index = skillIDIndexInPassLines[skillID];
      if (typeof index === 'undefined') {
        newPassLines.push(getPassLineItem(skillID, idSkillDataLink[skillID].label));
      } else {
        newPassLines.push(passLines[index]);
      }
    });
    formValue.passLines = newPassLines;
  }
  function removeSkill(index) {
    formValue.skills.splice(index, 1);
    formValue.passLines.splice(index, 1);
  }
  function handleScoreBlur(obj) {
    let score = obj.score;
    if (score || score === 0) {
      score < 0 && (score = 0);
      score > 100 && (score = 100);
      score = Math.round(score);
      obj.score = score;
    }
  }

  function setFormValue({ data_list, skill, title }) {
    const skills = [];
    const passLines = [];
    const skillIDScore = JSON.parse(skill);
    const idSkillDataLink = idSkillData.value;
    data_list.forEach(({ id: skillID }) => {
      skills.push(skillID);
      passLines.push(getPassLineItem(skillID, idSkillDataLink[skillID].label, Math.round(skillIDScore[skillID]) || 0));
    });
    Object.assign(formValue, {
      name: title,
      skills,
      passLines
    });
  }
  function getReqData() {
    const data = {
      type: listTypeTagVal.JOB
    };
    if (isEdit.value) {
      data['PlanningPosi[id]'] = jobID.value;
    }
    data['PlanningPosi[title]'] = formValue.name;
    const list = [];
    formValue.passLines.forEach(({ skillID, score }) => {
      list[Number(skillID)] = score;
    });
    data['PlanningPosi[skill]'] = list;
    return data;
  }

  defineExpose({
    openCreate() {
      modalTitle.value = '新增技能';
      jobID.value = '';
      modalShow.value = true;
    },
    openEdit(id) {
      modalTitle.value = '编辑技能';
      jobID.value = id;
      loading.value = true;
      reqItemDetail({
        type: listTypeTagVal.JOB,
        'PlanningPosi[id]': id
      }).then(({ code, data }) => {
        if (code === SUCCESS) {
          setFormValue(data);
          modalShow.value = true;
        }
      }).finally(() => {
        loading.value = false;
      });
    }
  });
</script>

<style lang="less" scoped>
  .skill-passling-item {
    :deep(.n-form-item-label) {
      position: relative;
    }

    :deep(.n-form-item-label__asterisk) {
      position: absolute;
      top: 0;
      right: 12px;
    }
  }

  .skill-name {
    box-sizing: border-box;
    width: 100%;
    padding-right: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .score-input {
    width: 265px;
  }

  .del-skill-passline {
    flex-shrink: 0;
    margin-left: 10px;
  }
</style>